.tile {
  display: flex;
  flex-flow: column nowrap;
  align-content: space-around;
  justify-content: space-around;
  font-family: "Rubik";
  color: white;
  padding-bottom: 0.8rem;
  align-self: center;
}

.title {
  font-weight: normal;
  align-self: center;
  border-radius: 30px;
}

.img {
  width: 50vw;
  max-width: 600px;
  align-self: center;
  border-radius: 10px;
}
.desc {
  align-self: center;
  text-align: center;
}
.tagBox {
  background-color: transparent;
  width: 50vw;
  max-width: 600px;
  display: flex;
  flex-flow: row wrap;
  align-content: space-around;
  justify-content: space-around;
  align-self: center;
  margin-top: 1rem;
}
.tag {
  border: solid rgba(169, 237, 190, 1);
  border-radius: 20px;
  border-width: 2px;
  width: fit-content;
  padding: 0.2rem;
  transition-duration: 200ms;
  margin: 0.2rem;
}
.tag:hover {
  background-color: rgba(255, 255, 255, 0.233);
}
a {
  color: white;
}
a:active {
  color: white;
}
.linkBox {
  position: relative;
  align-self: center;
}
#icon {
  font-size: 1.5rem;
  color: white;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  transition-duration: 300ms;
  cursor: pointer;
}
#icon:hover {
  color: rgba(169, 237, 190, 1);
}
