.card {
  height: 85vh;
  width: 68vw;
  position: relative;
  background: rgba(242, 237, 237, 0.089);
  mix-blend-mode: normal;
  box-sizing: border-box;
  box-shadow: 0px 5px 2px rgba(129, 127, 127, 0.4),
    inset 0px 4px 4px rgba(201, 200, 200, 0.11);
  backdrop-filter: blur(14px);
  border-radius: 60px;
  z-index: 4;
  display: flex;
  flex-flow: column wrap;
  align-content: center;
  justify-content: center;
  align-self: center;
}

.avatar {
  background-color: rgba(169, 237, 191, 0.459);
  background: linear-gradient(
    to right,
    rgba(169, 237, 190, 1),
    rgba(169, 237, 191, 0.459)
  );
  background: -webkit-linear-gradient(
    to right,
    rgba(169, 237, 190, 1),
    rgba(136, 139, 137, 1)
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 50%;
  height: auto;
  width: 15vw;
  max-width: 15rem;
  position: absolute;
  left: 10%;
  top: 10%;
}

.title {
  font-family: "Rubik", sans-serif;
  font-size: 2rem;
  text-shadow: 1px 1px #b3c1d3a6;
  font-weight: lighter;
  color: white;
  text-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25),
    inset 0px 1px 4px rgba(0, 0, 0, 0.06);
  align-self: center;
  width: fit-content;
  height: fit-content;
  margin-left: 1rem;
}
.text {
  font-family: "Rubik", sans-serif;
  font-size: 2rem;
  text-shadow: 2px 2px #b3c1d3a6;
  color: white;
  height: fit-content;
  width: fit-content;
  margin: 0;
  align-self: center;
  margin-top: 3rem;
  display: flex;
  align-content: center;
  justify-content: center;
}
.underline {
  font-size: 4rem;
  text-shadow: 2px 2px #b3c1d3a6;
  font-weight: normal;
  margin-left: 1rem;
  margin-right: 1rem;
  border-bottom: solid rgba(169, 237, 190, 1);
  border-width: 1rem;
}
.big {
  font-size: 2.5rem;
  border-width: 25px;
}

.triangleRight,
.triangleLeft {
  width: 1rem;
  height: 1rem;
  background-color: rgba(169, 237, 190, 1);
  align-self: center;
}
.triangleLeft {
  clip-path: polygon(0 0, 0% 100%, 100% 50%);
}
.triangleRight {
  clip-path: polygon(0 50%, 100% 100%, 100% 0);
}

@media only screen and (min-device-width: 375px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .card {
    height: 75vh;
    width: 90vw;
  }
}
