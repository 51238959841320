.card {
  height: 85vh;
  width: 68vw;
  background: rgba(242, 237, 237, 0.089);
  mix-blend-mode: normal;
  box-sizing: border-box;
  box-shadow: 0px 5px 2px rgba(129, 127, 127, 0.4),
    inset 0px 4px 4px rgba(201, 200, 200, 0.11);
  backdrop-filter: blur(14px);
  border-radius: 60px;
  z-index: 4;
  display: flex;
  flex-flow: column wrap;
  align-content: space-around;
  justify-content: space-evenly;
}
.title {
  font-family: "Rubik", sans-serif;
  font-size: 3rem;
  color: white;
  align-self: center;
}
.box {
  background-color: transparent;
  width: 66vw;
  height: 70vh;
  overflow-y: scroll;
}
.box::-webkit-scrollbar {
  width: 0.1rem;
}

.box::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.box::-webkit-scrollbar-thumb {
  background-color: rgba(169, 237, 190, 1);
}
@media only screen and (min-device-width: 375px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .card {
    height: 75vh;
    width: 90vw;
  }
  .box {
    height: 65vh;
    width: 80vw;
  }
}
