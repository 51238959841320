.card {
  height: 85vh;
  width: 68vw;
  background: rgba(242, 237, 237, 0.089);
  mix-blend-mode: normal;
  box-sizing: border-box;
  box-shadow: 0px 5px 2px rgba(129, 127, 127, 0.4),
    inset 0px 4px 4px rgba(201, 200, 200, 0.11);
  backdrop-filter: blur(14px);
  border-radius: 60px;
  z-index: 4;
  display: flex;
  flex-flow: row wrap;
  align-content: space-around;
  justify-content: space-evenly;
}
.title {
  font-family: "Rubik", sans-serif;
  font-size: 3rem;
  color: white;
}
.form {
  background-color: transparent;
  width: 60vw;
  height: 70vh;
  display: flex;
  flex-flow: column wrap;
  align-content: space-around;
  justify-content: space-evenly;
}
.input {
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
  padding: 0.5rem;
  width: 35vw;
  transition-duration: 200ms;
}
.triangle {
  color: rgba(169, 237, 190, 1);
}
#text {
  font-family: "Rubik", sans-serif;
  background-color: transparent;
  height: 20vh;
  width: 50vw;
  border: solid rgba(169, 237, 190, 1);
  border-width: 1px;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: white;
  transition: background-color 5000s ease-in-out 0s;
}
.input:focus {
  border-bottom: solid rgba(169, 237, 190, 1);
  border-width: 1px;
  font-size: 1rem;
}
.button {
  background-color: rgba(169, 237, 190, 0.5);
  border: none;
  height: 2rem;
  width: 4rem;
  outline: none;
  cursor: pointer;
  color: white;
  font-weight: bold;
  transition-duration: 200ms;
  align-self: center;
}
.button:hover {
  background-color: rgba(169, 237, 190, 1);
}
.sent {
  height: fit-content;
  width: fit-content;
  display: flex;
  flex-flow: column nowrap;
  align-content: space-around;
  justify-content: space-around;
}
#icon {
  color: rgba(169, 237, 190, 1);
  font-size: 5rem;
  align-self: center;
  margin-bottom: 1rem;
}
.thank {
  color: white;
  width: 50vw;
  align-self: center;
  text-align: center;
}
.waitBox {
  background-color: transparent;
  height: 2rem;
  width: 5rem;
  display: flex;
  align-content: space-around;
  justify-content: space-around;
}
.circle {
  background-color: rgba(226, 215, 215, 0.521);
  height: 0.7rem;
  width: 0.7rem;
  border-radius: 50%;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .card {
    height: 75vh;
    width: 90vw;
  }
  .form {
    width: 80vw;
  }
  .input {
    width: 70vw;
  }
  #text {
    width: 70vw;
  }
}
