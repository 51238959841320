.App {
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-flow: column wrap;
  align-content: center;
  justify-content: center;
  overflow: hidden;
  z-index: 1;
}

.container {
  width: 93vw;
  height: 86vh;
  max-width: calc(68vw + 200px);
  align-self: center;
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  perspective: 100px;
}
.App::-webkit-scrollbar {
  width: 0;
}
.App::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}

@media only screen and (min-device-width: 375px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .App {
    overflow: scroll;
    height: 100vh;
    justify-content: flex-start;
  }
  .container {
    width: 100vw;
    height: 100vh;
    flex-flow: column;
    align-items: center;
    align-content: flex-start;
    justify-content: flex-start;
  }
}
