.card {
  height: 85vh;
  width: 68vw;
  background: rgba(242, 237, 237, 0.089);
  mix-blend-mode: normal;
  box-sizing: border-box;
  box-shadow: 0px 5px 2px rgba(129, 127, 127, 0.4),
    inset 0px 4px 4px rgba(201, 200, 200, 0.11);
  backdrop-filter: blur(14px);
  border-radius: 60px;
  z-index: 4;
  display: flex;
  flex-flow: row wrap;
  align-content: space-around;
  justify-content: space-around;
}

.title {
  color: white;
}

.moreButton {
  color: rgba(169, 237, 190, 1);
  animation: colorize ease 500ms infinte;
  cursor: crosshair;
}

.box {
  font-family: "Rubik", sans-serif;
  color: white;
  font-size: 1.5rem;
  line-height: 2rem;
  background-color: transparent;
  width: 60vw;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
}

.box::-webkit-scrollbar {
  width: 0.1rem;
}

.box::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.box::-webkit-scrollbar-thumb {
  background-color: rgba(169, 237, 190, 1);
}

@keyframes colorize {
  0% {
    color: rgba(169, 237, 190, 1);
  }
  50% {
    color: white;
  }

  100% {
    color: rgba(169, 237, 190, 1);
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .card {
    height: 75vh;
    width: 90vw;
  }
  .box {
    padding-left: 20px;
    height: 65vh;
    width: 70vw;
    font-size: 1rem;
    padding-top: 20px;
  }
}
