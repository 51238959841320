.card {
  height: 85vh;
  width: 68vw;
  background: rgba(242, 237, 237, 0.089);
  mix-blend-mode: normal;
  box-sizing: border-box;
  box-shadow: 0px 5px 2px rgba(129, 127, 127, 0.4),
    inset 0px 4px 4px rgba(201, 200, 200, 0.11);
  backdrop-filter: blur(14px);
  border-radius: 60px;
  z-index: 4;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
}
.title {
  color: white;
  align-self: center;
  font-size: 1.5rem;
}
.skillBox {
  background-color: transparent;
  height: 30vh;
  width: 50vw;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
}
.toolBox {
  background-color: transparent;
  height: 30vh;
  width: 50vw;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
}
#icon {
  color: white;
  margin: 0 4vw 0 0;
  font-size: 4rem;
  transition-duration: 500ms;
  flex-shrink: 10;
}
#icon:hover {
  font-size: 5rem;
  color: rgba(169, 237, 190, 1);
}

@media only screen and (min-device-width: 375px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .title {
    margin-top: 2rem;
  }

  .card {
    height: 75vh;
    width: 90vw;
  }
  .toolBox {
    height: 10vh;
    width: 80vw;
    justify-content: space-around;
  }
  .skillBox {
    width: 80vw;
    height: 30vh;
    justify-content: space-around;
  }
  #icon {
    font-size: 4rem;
    margin: 0 4ve 4vw 0;
  }
}
