.box {
  height: fit-content;
  width: 20vw;
  max-width: 80px;
  display: flex;
  flex-flow: row nowrap;
  align-content: space-around;
  justify-content: space-evenly;
  position: relative;
  align-self: center;
}
#icon {
  font-size: 1.2rem;
  color: white;
  transition-duration: 500ms;
  opacity: 0.5;
  cursor: pointer;
}
#icon:hover {
  opacity: 1;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .box {
    position: absolute;
    top: 80vh;
    z-index: 10;
  }
}
