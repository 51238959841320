.container {
  background-color: rgb(5, 24, 48);
  color: white;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}
.element {
  background-color: rgba(169, 237, 190, 1);
  align-self: center;
  height: 2rem;
  width: 2rem;
  margin: 0.5rem;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .container {
    display: none;
  }
}
