.bar {
  height: 85vh;
  width: 25vw;
  max-width: 200px;
  flex-shrink: 0;
  display: flex;
  flex-flow: column wrap;
  align-content: space-around;
  justify-content: space-evenly;
  background: rgba(116, 156, 172, 0.466);
  mix-blend-mode: normal;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(241, 240, 240, 0.582),
    inset 0px 4px 4px rgba(201, 200, 200, 0.11);
  backdrop-filter: blur(14px);
  border-radius: 60px;
  margin-right: 1vw;
}

.element {
  height: fit-content;
  width: fit-content;
  color: white;
  font-weight: bold;
  border-bottom: solid transparent;
  border-width: 5px;
  opacity: 0.7;
  cursor: pointer;
  transition-duration: 200ms;
  text-align: center;
  align-self: center;
}
.element:hover {
  border-color: rgba(169, 237, 190, 1);
  opacity: 1;
  transform: scale(1.2);
}
.selectedElement {
  height: fit-content;
  width: fit-content;
  color: white;
  font-weight: bold;
  border-bottom: solid transparent;
  border-width: 5px;
  opacity: 0.7;
  cursor: pointer;
  transition-duration: 200ms;
  text-align: center;
  align-self: center;
  border-color: rgba(169, 237, 190, 1);
  opacity: 1;
}
.avatar {
  background-color: rgba(169, 237, 191, 0.459);
  background: linear-gradient(
    to right,
    rgba(169, 237, 190, 1),
    rgba(169, 237, 191, 0.459)
  );
  background: -webkit-linear-gradient(
    to right,
    rgba(169, 237, 190, 1),
    rgba(136, 139, 137, 1)
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 1px 4px rgba(0, 0, 0, 0.06);
  border-radius: 50%;
  height: auto;
  width: 10vw;
  max-width: 10rem;
  align-self: center;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .bar {
    width: 95vw;
    height: 5vh;
    border: none;
    max-width: none;
    background-color: transparent;
    box-shadow: none;
    backdrop-filter: none;
    flex-flow: row wrap;
    align-items: space-around;
    align-content: space-around;
    justify-content: space-around;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
}
